import EnvironmentConfig from '../interfaces/EnvironmentConfig';
import saravanaConfig from './saravana';

type ConfigMap = { [env: string]: EnvironmentConfig };

const configsMap: ConfigMap = {
  saravana: saravanaConfig,
};

const getEnvConfigs = () => {
  const currentEnv = process.env.REACT_APP_DEPLOY_ENV || 'dev';

  if (!currentEnv || !configsMap[currentEnv]) {
    throw new Error('No Env Found');
  }

  return configsMap[currentEnv];
};

export default getEnvConfigs();
