import EnvironmentConfig from '../interfaces/EnvironmentConfig';

const saravanaConfig: EnvironmentConfig = {
  surveyJsKey: 'empty',

  dashboardClassic: 'https://classic.crm.supersaravanastores.com',
  ruleServerUrl: 'https://classic.api.crm.supersaravanastores.com',
  apiServerUrl: 'https://api.crm.supersaravanastores.com',
  casaWebAppUrl: 'https://web.crm.supersaravanastores.com',
  commerceConnect: 'https://commerce-connect-up.crm.supersaravanastores.com',
  pwaUrl: 'qa.casarewards.com',
  dashboard: 'https://crm.supersaravanastores.com',
  ruleUIUrl: 'https://rule.crm.supersaravanastores.com',
  keycloakConfig: {
    url: `https://auth.crm.supersaravanastores.com/auth`,
    realm: `casa`,
    clientId: `casa_dashboard`,
  },
  socialMedia: {
    facebook: {
      appId: 'appId',
    },
  },
};

export default saravanaConfig;
